import React from 'react';
import '../styles/Footer.scss';
import logo from '../media/images/logo.png';

export default class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <div className="links">
          <img src={logo} className="logo" alt="Logo" />
          <div className="pages">
            <a className="link" href="./">Products</a>
            <a className="link" href="/careers">Carreers</a>
            <a className="link" href="/contactus">Contact Us</a>
          </div>
        </div>
        <div className="copyright">
          <p className="text">© {new Date().getFullYear()} LavaTeck. All rights Reserved.</p>
        </div>
      </div>
    )
  }
}