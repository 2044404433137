import eleasticSearch from '../images/icons/eleasticsearch.png';
import java from '../images/icons/java.png';
import kubernetes from '../images/icons/kubernetes.png';
import microservices from '../images/icons/microservices.png';
import qualityMonitroing from '../images/icons/quality-monitoring.png';
import react from '../images/icons/react.png';


export const iconBoxes = [{
    image: microservices,
    title: 'Microservices',
    text: 'Our system is running on microservice architecture'
  },
  {
    image: java,
    title: 'Java',
    text: 'We use Java to implement the most advanced features keeping the highest security and performance level'
  },
  {
    image: react,
    title: 'React',
    text: 'Our applications are built with React - the most effective and secure frontend technology.'
  },
  {
    image: kubernetes,
    title: 'Kubernetes',
    text: 'Our application deployment, scaling, and management is automated with Kubernetes - the top notch technology.'
  },
  {
    image: eleasticSearch,
    title: 'Elasticsearch',
    text: 'We use Elasticsearch - a distributed, multitenant-capable full-text search engine, to provide a the fastest search functionality.'
  },
  {
    image: qualityMonitroing,
    title: 'Quality Monitoring',
    text: 'We use Groovy with Spock and Geb frameworks to guaranty the quality of our products - all functionality is covered with automated API and UI tests which monitor the system status 24/7.'
  }
]