import businessHeader from '../images/business-header.png';
import React from 'react';

export const productsHeader = {
  image: businessHeader,
  title: 'Business Intelligence Tool',
  text: ''
}

export const whiteDemo = {
  title: 'Harness The Power Of Data & Analytics With The LavaTeck Business Intelligence Tool',
  text: <span>Business intelligence is the difference-maker in today’s competitive landscape.<br/> Acquiring and analysing data before extracting valuable insights about the performance of your business, is the process that will get you over the top.<br/><br/><strong>Our BI tool helps you do just that by allowing you to manage and understand your data.</strong></span>
}

export const textBoxes = [{
  title: 'Custom Dashboards',
  text: 'Build your own Dashboards based on the metrics that matter to your business, share them with your employees and have real-time visibility to the numbers that will boost your growth.'
},
{
  title: 'Scheduled Reports',
  text: 'Export customized reports, manage permissions and schedule report delivery in one go. Take ownership of your data and gain valuable insights with the power of our BI tool.'
},
{
  title: 'Data Sourcing',
  text: 'Source the data you need with SQL queries in a matter of clicks. Fast, efficient and accurate data sourcing and management.'
}]