import React from 'react';
import '../styles/WhiteDemo.scss';

export default class WhiteDemo extends React.Component {
  render() {
    return (
      <div className="whiteDemo">
        <h1 className="title">{this.props.whiteDemo.title}</h1>
        <hr/>
        <p className="text">{this.props.whiteDemo.text}</p>
        <a className="cta" href="mailto:info@lavateck.com">REQUEST DEMO</a>
      </div>
    )
  }
}