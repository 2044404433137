import React from 'react';
import '../styles/Arrow.scss';
import arrow from '../media/images/arrow.png';

export default class Arrow extends React.Component {
  render() {
    return (
      <div className="arrow">
        <img src={arrow} className="arrow" alt="Down Arrow" />
      </div>
    )
  }
}