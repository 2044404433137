import React from 'react';
import '../styles/Features.scss';
import {features} from '../media/data/crm';
import {isMobile} from 'react-device-detect';
import Flippy, { FrontSide, BackSide } from 'react-flippy';

export default class Features extends React.Component {
  render() {
    let icons
    if (isMobile) {
      icons = features.map((feature) =>
      <Flippy flipOnHover={false} flipOnClick={true} flipDirection="horizontal" ref={(r) => this.flippy = r} className="icon-box">

          <FrontSide className="front" animationDuration="1000" style={{borderRadius: '20px'}}>
            <img src={feature.image} className="image" alt="Icon" />
            <h2 className="box-title">{feature.title}</h2>
          </FrontSide>

          <BackSide className="back" animationDuration="1000" style={{borderRadius: '20px'}}>
            <p className="text">{feature.text}</p>
          </BackSide>

      </Flippy>
      );
    } else {
      icons = features.map((feature) =>
      <div className="feature-box">
            <img src={feature.image} className="image" alt="Icon" />
            <h2 className="box-title">{feature.title}</h2>
            <p className="text">{feature.text}</p>
      </div>
      );
    }
    return (
      <div className="features">
        <h1 className="title">Features:</h1>
        <div className="features-boxes">
          {icons}
        </div>
      </div>
    )
  }
}