import React from 'react';
import '../styles/TextBoxes.scss';
import Collapsible from 'react-collapsible';
import { Link, withRouter } from "react-router-dom";

function TextBoxes(props) {
  let cta = []
  if (props.showCta === true) {
    props.textBoxes.map((textBox, index) => 
      cta[index] = <Link className="cta" to={textBox.url}>EXPLORE</Link>
    )
  }

  var collapsibleOpen = document.getElementsByClassName('Collapsible');
  console.log(collapsibleOpen)
  // collapsibleOpen.style.backgroundColor = "grey";

  const collapsible = props.textBoxes.map((textBox, index) => 
    <Collapsible trigger={<h2 className="title">{textBox.title} <i className="arrow down"></i></h2>}>
      <p className="text">{textBox.text}</p>
      {cta[index]}
    </Collapsible>
  )

  const boxes = props.textBoxes.map((textBox, index) => 
    <div className="platform">
      <h2 className="title">{textBox.title}</h2>
      <p className="text">{textBox.text}</p>
      {cta[index]}
    </div>
  )

  return (
    <div className="textBoxes">
      {collapsible}
      {boxes}
    </div>
  )
}

export default withRouter(TextBoxes);
