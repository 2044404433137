import React from 'react';
import {Home, CRM, Platform, Business, Careers, ContactUs} from './pages';
import "./styles/App.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/" exact component={() => <Home />} />
          <Route path="/crm" exact component={() => <CRM />} />
          <Route path="/platform" exact component={() => <Platform />} />
          <Route path="/business" exact component={() => <Business />} />
          <Route path="/careers" exact component={() => <Careers />} />
          <Route path="/contactus" exact component={() => <ContactUs />} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
