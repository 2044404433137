import clients from '../images/features/clients.png';
import data from '../images/features/data.png';
import email from '../images/features/email.png';
import payments from '../images/features/payments.png';
import portal from '../images/features/portal.png';
import callbacks from '../images/features/callbacks.png';
import CRMBubbles from '../images/CRM-bubbles.png';


export const features = [{
    image: clients,
    title: 'Clients and Leads',
    text: 'Manage your clients and leads, create custom workflows and assign customers to Sales or Retention agents.'
  },
  {
    image: data,
    title: 'Data Permissions',
    text: 'Create a robust company structure by making sure that employees can access data according to their seniority and scope of work.'
  },
  {
    image: email,
    title: 'Custom Email Delivery',
    text: 'Automate your emails by setting made-to-measure triggers or adjusting the language via Sendgrid.'
  },
  {
    image: payments,
    title: 'Simplify and Optimize Payments',
    text: 'The CRM is fully integrated with the Naspay cashier so you can easily receive payments via a variety of payment methods.'
  },
  {
    image: portal,
    title: 'Client Portal',
    text: 'Welcome your clients with a client area that makes it easy for them to self-manage their accounts, funds and personal details.'
  },
  {
    image: callbacks,
    title: 'Callbacks & Click2call',
    text: 'Increase your sales with automatic communication and callback management.'
  }
]

export const productsHeader = {
  image: CRMBubbles,
  title: 'CRM',
  text: 'Our proprietary CRM provides a full spectrum of features for a broker, including client portal, leads and clients management, fully covered sales and retention flows, payments management, click2call, callbacks etc.'
}

