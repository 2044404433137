import React from 'react';
import {Menu, ProductsHeader, Footer, Arrow, DemoBadge, Vacancies} from '../components';
import {productsHeader, vacancies} from '../media/data/careers'

export default class Careers extends React.Component {
  render() {
    return (
      <div className="home">
        <Menu />
        <ProductsHeader productsHeader={productsHeader} showCta={false}/>
        <Arrow />
        <Vacancies vacancies={vacancies}/>
        <DemoBadge />
        <Footer />
      </div>
    )
  }
}