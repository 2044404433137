import React, { useState } from 'react'
import '../styles/Contact.scss';
import contactBubbles from '../media/images/contact-bubbles.png';
import contactBubblesMobile from '../media/images/contact-bubbles-mobile.png';
import { Axios, db } from '../firebase/firebaseConfig'

const Contact = () => {
    const [formData, setFormData] = useState({})

    const updateInput = e => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      })
    }
    const handleSubmit = event => {
      event.preventDefault()
      sendEmail()
      setFormData({
        name: '',
        email: '',
        message: '',
      })
    }
    const sendEmail = () => {
      Axios.post(
        'https://us-central1-lavateck-fc831.cloudfunctions.net/submit',
        formData
      )
        .then(res => {
          db.collection('emails').add({
            name: formData.name,
            email: formData.email,
            message: formData.message,
            time: new Date(),
          })
        })
        .catch(error => {
          console.log(error)
        })
    }

    return (
      <div className="contact">
        <div className="details">
          <img src={contactBubbles} className="image" alt="Contact Bubbles" />
          <img src={contactBubblesMobile} className="image-mobile" alt="Contact Bubbles" />
          <div className="details-text">
            <p>Address: Antheon 2, Monovoliko, Kato Polemidia,<br/>4151, Limassol, Cyprus</p>
            <a href="mailto:info@lavateck.com">info@lavateck.com</a><br/>
            <a href="tel:(+357) 25 717 577">(+357) 25 717 577</a>
          </div>
        </div>
        <div className="contact-form">
          <h2>Contact Us</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              required
              placeholder="Name*"
              onChange={updateInput}
              value={formData.name || ''}
            />
            <input
              type="email"
              name="email"
              required
              placeholder="Email*"
              onChange={updateInput}
              value={formData.email || ''}
            />
            <textarea
              type="text"
              name="message"
              required
              placeholder="Message*"
              onChange={updateInput}
              value={formData.message || ''}
            ></textarea>
            <button type="submit">Submit</button>
          </form>
          <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
        </a>
          <p>* – required</p>
        </div>
        <div className="details-text-mobile">
            <p>Address: Antheon 2, Monovoliko, Kato Polemidia,<br/>4151, Limassol, Cyprus</p>
            <a href="mailto:info@lavateck.com">info@lavateck.com</a><br/>
            <a href="tel:(+357) 25 717 577">(+357) 25 717 577</a>
          </div>
      </div>
    ) 
}

export default Contact
