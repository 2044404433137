import React from 'react';
import '../styles/ProductsHeader.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default class ProductsHeader extends React.Component {
  render() {
    let cta
    if (this.props.showCta === true) {
      cta = <a className="cta" href="mailto:info@lavateck.com">REQUEST DEMO</a>
    } else {
      cta = ""
    }
    return (
      <div className="products-header">
        <div className="left">
          <h1 className="title">{this.props.productsHeader.title}</h1>
          <hr/>
          <p className="text">{this.props.productsHeader.text}</p>
          {cta}
        </div>
        <div className="right">
          <LazyLoadImage visibleByDefault="true" className="orange-bubbles" alt="Orange Bubbles" src={this.props.productsHeader.image}/>
        </div>
      </div>
    )
  }
}