export const textBoxes = [{
  title: 'CRM',
  text: 'Our proprietary CRM provides a full spectrum of features for a broker, including client portal, leads and clients management, fully covered sales and retention flows, payments management, click2call, callbacks etc.',
  url: '/crm'
},
{
  title: 'Platform',
  text: 'The LavaTeck professional web trading platform comes with adaptive UI design for desktop and mobile use. It’s based on microservice architecture and modern technologies (Kafka, Java, Aeron, Spring, React, Redux, Docker, Kubernetes).',
  url: '/platform'
},
{
  title: 'BI Tool',
  text: 'Business intelligence (BI) is a crucial tool for strategic planning. It allows a company to gather, store, access and analyze corporate data. Take your decision making to the next level with LavaTeck.',
  url: '/business'
}]