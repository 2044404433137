import React from 'react';
import '../styles/Icons.scss';
import Flippy, { FrontSide, BackSide } from 'react-flippy';
import {iconBoxes} from '../media/data/icons';
import {isMobile} from 'react-device-detect';

export default class Icons extends React.Component {
  render() {
    const icons = iconBoxes.map((icon) =>
      <Flippy flipOnHover={(isMobile) ? false:true} flipOnClick={(isMobile) ? true:false} flipDirection="horizontal" ref={(r) => this.flippy = r} className="icon-box">

          <FrontSide className="front" animationDuration="1000" style={{borderRadius: '20px'}}>
            <img src={icon.image} className="image" alt="Icon" />
            <h1 className="title">{icon.title}</h1>
          </FrontSide>

          <BackSide className="back" animationDuration="1000" style={{borderRadius: '20px'}}>
            <h1 className="title">{icon.title}</h1>
            <p className="text">{icon.text}</p>
          </BackSide>

      </Flippy>
    );

    return (
      <div className="icons">
        <h1 className="title">We're Experts In:</h1>
        <hr/>
        <div className="icon-boxes">
          {icons}
        </div>
      </div>
    )
  }
}