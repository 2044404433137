import React from 'react';
import {Menu, Footer, Contact, ProductsHeader} from '../components';
import {productsHeader} from '../media/data/contactus'
import '../styles/ContactUs.scss'

export default class ContactUs extends React.Component {
  render() {
    return (
      <div className="contactus">
        <Menu/>
        <ProductsHeader productsHeader={productsHeader} showCta={false}/>
        <Contact />
        <Footer />
      </div>
    )
  }
}