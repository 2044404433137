import React from 'react';
import '../styles/Vacancies.scss';

export default class WhiteDemo extends React.Component {
  render() {
    let vacancyBoxes = this.props.vacancies.map((vacancyBox) => 
      <div className="vacancy-box">
        <h2 className="title">{vacancyBox.title}</h2>
        <a className="cta" href="mailto:info@lavateck.com">APPLY NOW</a>
      </div>
    )
    return (
      <div className="vacancies">
        <h1 className="title">LavaTech Vacancies</h1>
        <hr/>
        <div className="vacancy-boxes">
          {vacancyBoxes}
        </div>
      </div>
    )
  }
}