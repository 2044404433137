import React from 'react';
import {Menu, Header, TextBoxes, Footer, Arrow, Icons, Contact} from '../components';
import {textBoxes} from '../media/data/home'

export default class Home extends React.Component {
  render() {
    return (
      <div className="home">
        <Menu/>
        <Header/>
        <TextBoxes textBoxes={textBoxes} showCta={true}/>
        <Arrow />
        <Icons />
        <Contact />
        <Footer />
      </div>
    )
  }
}