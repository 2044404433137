import careerBubbles from '../images/career-bubbles.png';

export const productsHeader = {
  image: careerBubbles,
  title: 'Careers',
  text: 'LavaTeck is always on the lookout for great talent. Have a look at the positions below and if there is nothing to match your skill-set, do not hesitate to send through your CV. We always keep them on file and we will contact you if we see a possible match.'
}

export const vacancies = [{
  title: 'Business Development Manager'
},
{
  title: 'Business Development Manager'
},
{
  title: 'Business Development Manager'
}]