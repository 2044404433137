import React from 'react';
import {Menu, ProductsHeader, Footer, Arrow, Features, DemoBadge} from '../components';
import {productsHeader} from '../media/data/crm'

export default class CRM extends React.Component {
  render() {
    return (
      <div className="home">
        <Menu/>
        <ProductsHeader productsHeader={productsHeader} showCta={true}/>
        <Arrow />
        <Features />
        <DemoBadge />
        <Footer />
      </div>
    )
  }
}