import React from 'react';
import '../styles/DemoBadge.scss';

export default class DemoBadge extends React.Component {
  render() {
    return (
      <div className="demoBadge">
        <div className="left">
          <h1 className="title">Give Your Business A Competitive Advantage</h1>
          <p className="text">Leverage the incredible features of the LavaTeck CRM and get ahead of your competition.</p>
        </div>
        <div className="right">
          <a className="cta" href="mailto:info@lavateck.com">REQUEST DEMO</a>
        </div>
      </div>
    )
  }
}