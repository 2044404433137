import React from 'react';
import '../styles/Menu.scss';
import logo from '../media/images/logo.png';
import {Link, withRouter} from "react-router-dom";
import {isMobile} from 'react-device-detect';
import {elastic as Burger} from 'react-burger-menu'
import Collapsible from 'react-collapsible';

function Menu(props) {
  if (isMobile) { 
    return (
      <div className="menu">
        <Link className="menu-item" to="/">
          <img src={logo} className="logo" alt="Logo" />
        </Link>
        <Burger outerContainerId={ "root" }right>
          <Collapsible trigger={<p className="dropdown">PRODUCTS</p>}>
            <Link className="menu-item" to="/crm">
              CRM
            </Link>
            <Link className="menu-item" to="/platform">
              PLATFORM
            </Link>
            <Link className="menu-item" to="/business">
              BI TOOL
            </Link>
          </Collapsible>
          <Link className="menu-item" to="/careers">
            CAREERS
          </Link>
          <Link className="menu-item" to="/contactus">
            CONTACT US
          </Link>
        </Burger>
      </div>
    )
  } else {
    return (
      <div className="menu">
        <Link className="menu-item" to="/">
          <img src={logo} className="logo" alt="Logo" />
        </Link>
        <div className="menu-items">
          <Collapsible trigger={<p className="dropdown">PRODUCTS</p>}>
            <Link className="menu-item" to="/crm">
              CRM
            </Link>
            <Link className="menu-item" to="/platform">
              PLATFORM
            </Link>
            <Link className="menu-item" to="/business">
              BI TOOL
            </Link>
          </Collapsible>
          <Link className="menu-item" to="/careers">
            Careers
          </Link>
          <Link className="menu-item" to="/contactus">
            Contact Us
          </Link>
        </div>
      </div>
    )
  }
}
export default withRouter(Menu);
