import React from 'react';
import '../styles/Header.scss';
import OrangeBubbles from '../media/images/orange-bubbles.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default class Header extends React.Component {
  render() {
    return (
      <div className="header">
          <h2 className="text">Give Your Brokerage A Competitive Advantage With LavaTeck’s Cutting Edge Trading Suite</h2>
          <LazyLoadImage visibleByDefault="true" className="orange-bubbles" alt="Orange Bubbles" src={OrangeBubbles}/>
      </div>
    )
  }
}