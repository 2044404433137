import platformBubbles from '../images/platform-bubbles.png';
import React from 'react';

export const productsHeader = {
  image: platformBubbles,
  title: 'PLATFORM',
  text: 'LavaTeck Is a professional web trading platform with adaptive UI design for desktop and mobile use. It’s based on microservice architecture and modern technologies (Kafka, Java, Aeron, Spring, React, Redux, Docker, Kubernetes).'
}

export const whiteDemo = {
  title: 'Not Just Another Trading Platform',
  text: <span>The LavaTeck platform comes with a unique set of features that will make trading <strong>easy, accessible, and intuitive.</strong></span>
}