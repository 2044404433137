import React from 'react';
import {Menu, ProductsHeader, TextBoxes, Footer, Arrow, DemoBadge, WhiteDemo} from '../components';
import {productsHeader, whiteDemo} from '../media/data/business'
import {textBoxes} from '../media/data/business'

export default class Business extends React.Component {
  render() {
    return (
      <div className="home">
        <Menu />
        <ProductsHeader productsHeader={productsHeader} showCta={false}/>
        <TextBoxes textBoxes={textBoxes} showCta={false}/>
        <Arrow />
        <WhiteDemo whiteDemo={whiteDemo}/>
        <DemoBadge />
        <Footer />
      </div>
    )
  }
}